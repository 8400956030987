<template>
    <template v-if="list_document_exist.includes(value['path'])">
        <LinkUpload :href="translate_pdf_return[value['path']]" v-if="translate_pdf_return && translate_pdf_return[value['path']]" :style="style_for_icon" target="_blank" rel="noopener">
            <div class="icon-translate-pdf-done"></div>
        </LinkUpload>
        <ve-progress :progress="progress(value['path'])" :legend="seconds_remaining(value['path'])" v-else-if="loading[value['path']]" :size="25" :style="style_for_icon" :loading="status(value['path'])"/>
        <div class="icon-translate-pdf" v-else-if="list_document_exist.includes(value['path']) && value['language'] !== $i18n.locale && (user['default_language'] !== 'fr' || host.includes('prismheor'))" style="display:inline-flex; margin-right:5px;" @click="translate_pdf(value['path'])"></div>

        <LinkUpload :href="value['path']" class="test" style="display: inline-flex; margin-right: 5px; margin-left:5px;" target="_blank" rel="noopener">
            <div class="icon-fiche"></div>
        </LinkUpload>   
    </template>
    <a :href="value['link']" v-else-if="value['link']" target="_blank" rel="noopener">
        <div class="icon-www"></div>
    </a> 
    <span v-else> - </span>
</template>

<script>
import LinkUpload from './LinkUpload.vue'
import { VeProgress } from "vue-ellipse-progress";
export default {
    name : "KeyDocumentIcon",
    components: { VeProgress, LinkUpload },
    data(){
        return {
            style_for_icon:"display: inline-flex; margin-right: 5px; margin-left:5px;"
        }
    },
    props : {
        value : {
            style : Object
        },
        list_document_exist : {
            style : Array
        }
    },
    computed : {
        translate_pdf_return(){
            return this.$store.getters['translation/translate_pdf_return']
        },
        loading(){
            return this.$store.getters['translation/loading']
        },
        process(){
            return this.$store.getters['translation/process']
        },
        user(){
            return this.$store.getters['auth/user']
        },
        host(){
            return location.hostname
        }
    },
    methods : {
        translate_pdf(path){
            this.$store.dispatch('translation/translate_pdf', {document_path:path, language:this.$i18n.locale})
        },
        seconds_remaining(path){
            return this.process[path] && this.process[path].seconds_remaining ? this.process[path].seconds_remaining : 0
        },
        progress(path){
            return this.process[path] && this.process[path].seconds_remaining && this.process[path].max_seconds_remaining ? (this.process[path].seconds_remaining*100)/this.process[path].max_seconds_remaining : 0
        },
        status(path){
            return this.process[path] && this.process[path].status == 'translating' ? false : true
        }
    },
}
</script>

<style>

</style>